// This mixin is to add css breakpoints
// From: https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/

/**
 * HOW TO USE:
 * .some-class {
 *     width: 20px;
 *     @include respond-to(small) { width: 40px; }
 *     @include respond-to(medium) { width: 80px; }
 *     @include respond-to(large) { width: 160px; }
 * }
 */

$breakpoints: (
    small:   576px,
    medium:  768px,
    large:   992px,
    xlarge:  1200px
) !default;

// xsmall -> never used this is the default css value
// small
// medium
// large
// xlarge
@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
    @else {
        @media only screen and (min-width: $breakpoint) {
            @content;
        }
    }
}