@font-face {
    font-family: 'BrickDisplay';
    src: url('./../assets/fonts/brick-display-black-pro.eot');
    src: url('./../assets/fonts/brick-display-black-pro.eot?#iefix') format('embedded-opentype'),
         url('./../assets/fonts/brick-display-black-pro.woff2') format('woff2'),
         url('./../assets/fonts/brick-display-black-pro.woff') format('woff'),
         url('./../assets/fonts/brick-display-black-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ObjectSans';
    src: url('./../assets/fonts/ObjectSans-Bold.eot');
    src: url('./../assets/fonts/ObjectSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('./../assets/fonts/ObjectSans-Bold.woff2') format('woff2'),
         url('./../assets/fonts/ObjectSans-Bold.woff') format('woff');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: 'ObjectSans';
    src: url('./../assets/fonts/ObjectSans-Medium.eot');
    src: url('./../assets/fonts/ObjectSans-Medium.eot?#iefix') format('embedded-opentype'),
         url('./../assets/fonts/ObjectSans-Medium.woff2') format('woff2'),
         url('./../assets/fonts/ObjectSans-Medium.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ObjectSans';
    src: url('./../assets/fonts/ObjectSans-Regular.eot');
    src: url('./../assets/fonts/ObjectSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('./../assets/fonts/ObjectSans-Regular.woff2') format('woff2'),
         url('./../assets/fonts/ObjectSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family:    'ObjectSans', sans-serif;
    font-size:      11px;
}