/**
 * You can add global styles to this file, and also import other style files
 * 
 * All class defined in here should start with .ll-, by prepending those classes it is clear in templates a
 * global defined class is used. (You can still override or extend them in your local scss files)
 */
@use '@angular/material' as mat;
@include mat.core();
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

@import 'colors.scss';
@import 'fonts.scss';
@import '_respond-to.scss';

$default: 'ObjectSans';

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-typography: mat.define-typography-config(
    $font-family: $default
);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
  typography: $my-typography,
));

@include mat.all-component-themes($my-theme);

.transparent-background {
    background-color: transparent !important;
    background-image: transparent !important;
}

// hide the decorative elements when the transparent-background is active
.transparent-background .ll-dark-shading::before,
.transparent-background .ll-dark-shading::after,
.transparent-background main::before,
.transparent-background main::after {
    background-color: transparent !important;
    background-image: none !important;
}


body {
    width:                  100vw;
    padding:                0;
    margin:                 0;
    color:                  map-get($map: $colors, $key: _llWhite);
    background-color:       map-get($map: $colors, $key: _llAquaDeep);

    // Do not allow text selection
    -webkit-touch-callout:  none; /* iOS Safari */
    -webkit-user-select:    none; /* Safari */
    -khtml-user-select:     none; /* Konqueror HTML */
    -moz-user-select:       none; /* Old versions of Firefox */
    -ms-user-select:        none; /* Internet Explorer/Edge */
    user-select:            none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

h2 {
    text-decoration:        underline;
    margin:                 0;
    font-weight:            400;
    font-size:              15px;
}

h3 { 
    font-weight:            400;
    font-size:              13px;
}

p {
    padding:                0;
    margin:                 0;
    font-weight:            300;
    line-height:            1.3;
}

li {
    line-height:            1.3;
}

span {
    font-weight:            300;
}

* {
    box-sizing:             border-box;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style:     none;  /* IE and Edge */
    scrollbar-width:        none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display:            none;
    }
}

main {
    height:                 100vh;
    padding:                5px 5px 5px 5px;

    $mask-height-top:       10px;
    $mask-thresshold-top:   calc($mask-height-top / 2);
    $mask-height-bottom:    25px;
    $mask-thresshold-bottom:calc($mask-height-bottom / 3);
    -webkit-overflow-scrolling: touch;

    &:before {
        content:            '\A';
        position:           fixed;
        z-index:            30;
        left:               0;
        right:              0;
        bottom:             0;
        height:             $mask-height-bottom;
        background-image:   -moz-linear-gradient(top,rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -webkit-linear-gradient(top, rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -o-linear-gradient(top, rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -ms-linear-gradient(top, rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   linear-gradient(to top, rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
    }

    &:after {
        content:            '\A';
        position:           fixed;
        z-index:            30;
        left:               0;
        right:              0;
        top:                0;
        height:             $mask-height-top;
        background-image:   -moz-linear-gradient(bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -webkit-linear-gradient(bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -o-linear-gradient(bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -ms-linear-gradient(bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   linear-gradient(to bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
    }
}

hr {
    display:                block;
    border:                 unset;
    border-bottom:          0.5px dashed map-get($map: $colors, $key: _llWhite);
    margin:                 8px 0;
    opacity:                0.6;
}

.ll-container {
    width:                  100vw;
    height:                 100vh;
    overflow:               hidden;
}

.ll-row-ctr {
    display:                flex;
    flex-direction:         row;
}

.ll-column-ctr {
    display:                flex;
    flex-direction:         column;
}

.ll-space-between {
    justify-content:        space-between;
    align-items:            center;
}

.ll-card {
    padding:                6px;
    overflow-y:             scroll;
}

.ll-card-styled {
    background-color:       map-get($map: $colors, $key: _llAquaLight);
    border-radius:          10px;
    padding:                0 10px;
    overflow-y:             scroll;
    box-shadow:             0px 0px 5px 3px rgba(0,0,0,0.1);

    &:not(.overflow-mask) {
        padding:            10px
    }

    &.overflow-mask .ll-overflow-y {
        overflow-x:         hidden;
        padding:            10px 0 30px 0;
    }
}

.ll-light-shading {

    $mask-height-top:       10px;
    $mask-thresshold-top:   calc($mask-height-top / 2);
    $mask-height-bottom:    30px;
    $mask-thresshold-bottom:calc($mask-height-bottom / 2);

    position:               relative;
    max-height:             100%;

    &.overflow-mask .ll-overflow-y {
        overflow-y:         auto;
        width:              100%;
        height:             100%;
        padding:            $mask-height-top 0 $mask-height-bottom 0;

        -webkit-overflow-scrolling: touch;
    }

    &.overflow-mask:before {
        content:            '\A';
        position:           absolute;
        z-index:            5;
        left:               10px;
        right:              10px;
        bottom:             0;
        height:             $mask-height-bottom;
        background-image:   -moz-linear-gradient(top,rgba(57, 107, 111, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -webkit-linear-gradient(top, rgba(57, 107, 111, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -o-linear-gradient(top, rgba(57, 107, 111, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -ms-linear-gradient(top, rgba(57, 107, 111, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   linear-gradient(to top, rgba(57, 107, 111, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
    }

    &.overflow-mask::after {
        content:            '\A';
        position:           absolute;
        z-index:            5;
        left:               10px;
        right:              10px;
        top:                0;
        height:             $mask-height-top;
        background-image:   -moz-linear-gradient(bottom, rgba(57, 107, 111, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -webkit-linear-gradient(bottom, rgba(57, 107, 111, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -o-linear-gradient(bottom, rgba(57, 107, 111, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -ms-linear-gradient(bottom, rgba(57, 107, 111, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   linear-gradient(to bottom, rgba(57, 107, 111, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
    }
}

.ll-dark-shading {

    $mask-height-top:       10px;
    $mask-thresshold-top:   calc($mask-height-top / 2);
    $mask-height-bottom:    30px;
    $mask-thresshold-bottom:calc($mask-height-bottom / 2);

    position:               relative;
    max-height:             100%;

    &.overflow-mask .ll-overflow-y {
        overflow-y:         auto;
        width:              100%;
        height:             100%;
        padding:            $mask-height-top 0 $mask-height-bottom 0;

        -webkit-overflow-scrolling: touch;
    }

    &.overflow-mask:before {
        content:            '\A';
        position:           absolute;
        z-index:            5;
        left:               0;
        right:              0;
        bottom:             0;
        height:             $mask-height-bottom;
        background-image:   -moz-linear-gradient(top,rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -webkit-linear-gradient(top, rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -o-linear-gradient(top, rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   -ms-linear-gradient(top, rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
        background-image:   linear-gradient(to top, rgba(36, 91, 96, 1) $mask-thresshold-bottom, rgba(57, 107, 111, 0) $mask-height-bottom);
    }

    &.overflow-mask::after {
        content:            '\A';
        position:           absolute;
        z-index:            5;
        left:               0;
        right:              0;
        top:                0;
        height:             $mask-height-top;
        background-image:   -moz-linear-gradient(bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -webkit-linear-gradient(bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -o-linear-gradient(bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   -ms-linear-gradient(bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
        background-image:   linear-gradient(to bottom, rgba(36, 91, 96, 1) $mask-thresshold-top, rgba(57, 107, 111, 0) $mask-height-top);
    }
}

.ll-triangle-left {
    width:                  0; 
    height:                 0;
    border-right:           6px solid map-get($map: $colors, $key: _llWhite);
    border-top:             5px solid transparent;
    border-bottom:          5px solid transparent;
}


.ll-triangle-right {
    width:                  0; 
    height:                 0;
    border-left:            6px solid map-get($map: $colors, $key: _llWhite);
    border-top:             5px solid transparent;
    border-bottom:          5px solid transparent;
}

.ll-triangle-bottom {
    width:                  0; 
    height:                 0; 
    border-left:            6px solid transparent;
    border-right:           5px solid transparent;
    border-top:             5px solid map-get($map: $colors, $key: _llWhite);
}

// Apexcharts tooltip styling
.evolution-tooltip {
    padding:                5px;
}

.evolution-dialog .mat-mdc-dialog-surface.mdc-dialog__surface {
    background-color:       map-get($map: $colors, $key: _llAquaLight);
}

@include respond-to(medium) {
    main {
        width:              100vw;
        height:             100vh;
        overflow:           hidden;
        padding:            5px 30px 5px 5px;

        &:before {
            content:        none;
        }

        &:after {
            content:        none;
        }
    }
}